
import { mapGetters } from 'vuex'
import ThemeChanger from '@/components/ui/themechanger/index'

export default {
  name: 'top-main-nav',
  components: {
    ThemeChanger
  },
  props: {
    elevation: {
      type: String,
      default: '3'
    },
    padding: {
      type: String,
      default: 'pa-5'
    }
  },

  data() {
    return {
      showLanding: true
    }
  },

  computed: {
    ...mapGetters({
      authed: 'auth/authed',
      user: 'auth/claims',
      mobileDrawer: 'mobileDrawer',
      shopSettings: 'shopSettings',
      seller: 'seller',
      settings: 'settings'
    }),
    drawer: {
      get() { return this.mobileDrawer },
      set(v) { this.setMobileDrawer(v) }
    },
    accountLinkBase() {
      const base = process.env.SSO_URL
      // const append = process.env.NODE_ENV === 'development' ? window.location : this.settings.urls.backoffice
      const append = this.settings.urls.backoffice
      return `${base}/?redirectTo=${append}&hide_back=true`
    },
    loginUrl() {
      const base = process.env.SSO_URL
      return `${base}/?redirectTo=${window.location}&sponsorId=` + this.seller.id
    },
    registerUrl() {
      const base = process.env.SSO_URL
      return `${base}/register?redirectTo=${window.location}&sponsorId=` + this.seller.id
    }
  },

  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
    },
    async fbLogin() {
      const facebook = new this.$fireModule.auth.FacebookAuthProvider()
      await this.$store.dispatch('auth/loginWithProvider', facebook)
    },
    async googleLogin() {
      const google = new this.$fireModule.auth.GoogleAuthProvider()
      await this.$store.dispatch('auth/loginWithProvider', google)
    }
  }
}
