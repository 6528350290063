/*** Form Input Rules */

// Required
export const required = propertyName => {
  return v => !!v || `${propertyName} is required`
}

// Minimum Length
export const minLength = (propertyName, minLength) => {
  return v => (v && v.length >= minLength) || `${propertyName} must be more than ${minLength}`
}

// Email rule
const emailRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
export const email = v => v => emailRegex.test(v) || 'Please use a valid email.'

// Same As/equal strings rulee
export const sameAs = (propertyName, compareValue) => {
  return v => (v && v === compareValue) || `${propertyName} must be the same.`
}

// Url rule
const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)(?:\.(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)*(?:\.(?:[a-z\u00A1-\uFFFF]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
export const url = v => !v || urlRegex.test(v) || 'Please use a valid url.'

// Username rule
const usernameRegex = /[~`@!#$%^&*+=[\]\\'; ,/{}|\\":<>?]/
export const username = v => !usernameRegex.test(v) ||
'Your channel name can have - and . in it but no spaces or other special characters.'

export const phone = v => v => {
  return !v ||
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v) ||
  'Please use a valid phone number.'
}

const ccRegex = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|↵(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])↵[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/
export const creditCard = v => !v || ccRegex.test(v) || 'Please use a valid credit card number.'
