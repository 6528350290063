/**
 * Actions for Events module
 * imported in store/events.js
 */
export default {
  /**********
   * CREATE
   * **********/

  /**
   * Action to create a new event
   *
   * @param {object} params
   * @returns {promise}
   */
  create({ commit }, params) {
    return this.$apiEvents.post('/events/edit', params).catch(e => console.log(e))
  },

  /**********
   * READ
   * **********/

  /**
   * Get all events
   * @returns {promise}
   */
  async getAll({ rootGetters }) {
    const sid = rootGetters.seller.id
    const { data } = await this.$apiEvents.$get(`/events?sponsor_id=${sid}`)
    return data
  },

  /**********
   * UPDATE
   * **********/

  /**
   * Action to update an event
   * @param {object} formData
   * @returns {promise}
   */
  async updateEvent({ dispatch }, formData) {
    const { data } = await this.$apiEvents.put(`/events/edit/${formData.id}`, formData)
    return data
  },

  /**********
   * DELETE
   * **********/

  /**
   * Action to delete an event
   */
  async delete() {
  }
}
