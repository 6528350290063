/**
 * Mutations for Checkout module
 * imported in store/checkout.js
 */
export default {
  /**
   * Sets the checkout pid
   * @param {object} state
   * @param {string} pid
   */
  setCheckoutPid(state, pid) {
    state.checkoutPid = pid
    if (!pid) {
      state.expires = null
    } else {
      // Set expires to 10 minutes from now
      state.expires = Date.now() + 600000
    }
  },

  /**
   * Sets the checkout session
   * @param {object} state
   * @param {object} session
   */
  setSession(state, session) {
    state.session = session
    if (!session) {
      state.expires = null
    } else {
      // Set expires to 10 minutes from now
      state.expires = Date.now() + 600000
    }
  },

  /**
   * Sets the checkout autoship plan
   * @param {object} state
   * @param {object} plan
   */
  setAutoshipPlan(state, plan) {
    state.autoshipPlan = plan
  },

  /**
   * Sets the payman payment id
   * @param {object} state
   * @param {string} paymanPaymentId
   */
  setPaymanPaymentId(state, paymanPaymentId) {
    state.paymanPaymentId = paymanPaymentId
    if (paymanPaymentId) {
      // Set expires to 10 minutes from now
      // If paypal causes navigation we rely on this payment
      state.expires = Date.now() + 600000
    }
  },

  /**
   * Sets the contact info
   * @param {object} state
   * @param {object} contactInfo
   */
  setContactInfo(state, contactInfo) {
    state.contactInfo = contactInfo
  },

  /**
   * Set checkoutUpdating
   * @param {object} state
   * @param {boolean} checkoutUpdating
   */
  setCheckoutUpdating(state, checkoutUpdating) {
    state.checkoutUpdating = checkoutUpdating
  },

  /**
   * Clears the checkout process session
   * @param {object} state
   */
  clearSession(state) {
    state.checkoutPid = null
    state.session = null
    state.payment = null
    state.autoshipPlan = null
    state.checkoutUpdating = false
    state.paymanPaymentId = null
    state.contactInfo = null
    state.expires = null
  },

  /**
   * Sets the checkout payment info
   * @param {object} state
   * @param {object|null} payment
   */
  setPayment(state, payment) {
    state.payment = payment
  },

  /**
   * Sets the checkout expiration
   * Prevents persisted storage loading after time passed
   * @param {object} state
   * @param {number|null} expires
   */
  setExpires(state, expires) {
    state.expires = expires
  }
}
