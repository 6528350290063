import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e3e9ab3 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _47ca0f46 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _71f86922 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4c30a4f2 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _5f70148e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _50e7e30d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _a36df66e = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _dd30f53a = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _b14c32e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _40a1b3da = () => interopDefault(import('../pages/checkout/process/index.vue' /* webpackChunkName: "pages/checkout/process/index" */))
const _5c2ddac8 = () => interopDefault(import('../pages/checkout/receipt/index.vue' /* webpackChunkName: "pages/checkout/receipt/index" */))
const _4f874e30 = () => interopDefault(import('../pages/checkout/terms.vue' /* webpackChunkName: "pages/checkout/terms" */))
const _be43d19e = () => interopDefault(import('../pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _7eeaab44 = () => interopDefault(import('../pages/shop/_slug/index.vue' /* webpackChunkName: "pages/shop/_slug/index" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _2e3e9ab3,
    name: "checkout"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact"
  }, {
    path: "/events",
    component: _47ca0f46,
    name: "events"
  }, {
    path: "/login",
    component: _71f86922,
    name: "login"
  }, {
    path: "/password-reset",
    component: _4c30a4f2,
    name: "password-reset"
  }, {
    path: "/privacy",
    component: _5f70148e,
    name: "privacy"
  }, {
    path: "/register",
    component: _50e7e30d,
    name: "register"
  }, {
    path: "/returns",
    component: _a36df66e,
    name: "returns"
  }, {
    path: "/shop",
    component: _dd30f53a,
    name: "shop"
  }, {
    path: "/terms",
    component: _b14c32e6,
    name: "terms"
  }, {
    path: "/checkout/process",
    component: _40a1b3da,
    name: "checkout-process"
  }, {
    path: "/checkout/receipt",
    component: _5c2ddac8,
    name: "checkout-receipt"
  }, {
    path: "/checkout/terms",
    component: _4f874e30,
    name: "checkout-terms"
  }, {
    path: "/events/:id",
    component: _be43d19e,
    name: "events-id"
  }, {
    path: "/shop/:slug",
    component: _7eeaab44,
    name: "shop-slug"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
