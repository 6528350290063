
export default {
  props: {
    src: {
      type: String,
      default: 'https://dummyimage.com/16:9x100'
    },
    alt: {
      type: String,
      default: 'placeholder'
    },
    width: {
      type: String,
      default: '140px'
    },
    height: {
      type: String,
      default: '80%'
    },
    dark: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
}
