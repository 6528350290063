

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","messages":{"en":{"welcome":"welcome","hello":"submit","submit":"submit","form":"form","email":"Email","submitPayment":"submit payment","isRequired":"is required","ccNumber":"Credit Card Number","ccRequiredMsg":"A credit card number is required","ccErrorMsg":"A valid credit card number is required","emailRequiredMsg":"An email is required","emailErrorMsg":"A valid email is required"},"es":{"welcome":"bienvenido","hello":"hola","submit":"enviar","form":"formar","email":"Email","submitPayment":"enviar pago","isRequired":"se requiere","ccNumber":"Número de Tarjeta de Crédito","ccRequiredMsg":"Una tarjeta de crédito","ccErrorMsg":"Se requiere un número de tarjeta de crédito válido","emailRequiredMsg":"Se requiere un email","emailErrorMsg":"Se requiere un e-mail válido"},"fr":{"welcome":"bienvenue","hello":"bonjour","submit":"soumettre","form":"forme","email":"E-mail","submitPayment":"soumettre le paiement","isRequired":"est requis","ccNumber":"Numéro de Carte de Crédit","ccRequiredMsg":"Une carte de crédit","ccErrorMsg":"Un numéro de carte de crédit valide est requis","emailRequiredMsg":"Un e-mail est requis","emailErrorMsg":"Une adresse courriel valide est requise"}}},
  vueI18nLoader: true,
  locales: [{"code":"en","iso":"en-US","name":"English"},{"code":"es","iso":"es-MX","name":"Español"},{"code":"fr","iso":"fr-FR","name":"Français"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  useCookie: true,
  normalizedLocales: [{"code":"en","iso":"en-US","name":"English"},{"code":"es","iso":"es-MX","name":"Español"},{"code":"fr","iso":"fr-FR","name":"Français"}],
  localeCodes: ["en","es","fr"],
}

export const localeMessages = {}
