/**
 * State for Auth module
 * imported in store/auth.js
 * @returns {function}
 */
export default function() {
  return {
    tenantInfo: null,
    authed: false,
    authUser: null,
    claims: null,
    user: null,
    token: null,
    profiles: [],
    redirectUrl: null,
    savedAddresses: {},
    savedPaymentInfo: null
  }
}
