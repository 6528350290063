
import { mapGetters } from 'vuex'
import TopNav from '@/components/ui/navigation/top'
import LeftNav from '@/components/ui/navigation/left-main'
import CartDrawer from '@/components/ui/drawers/cart'
import CartExpiredModal from '@/components/cart/cart-expired-modal'
import MobileDrawer from '@/components/ui/navigation/mobile'
import ShopFooter from '@/components/ui/footer'

export default {
  name: 'default-layout',

  middleware: ['guards', 'shop', 'theme'],
  components: { TopNav, LeftNav, CartDrawer, CartExpiredModal, MobileDrawer, ShopFooter },

  head() {
    return {
      titleTemplate: titleChunk => {
        return titleChunk ? `${titleChunk} - ${this.settings.company.name}` : this.settings.company.name
      }
    }
  },

  computed: {
    ...mapGetters({
      shopSettings: 'shopSettings',
      settings: 'settings',
      globalModal: 'globalModal'
    })
  }
}
