export default async ({ store }) => {
  if (!store.getters['cart/id']) {
    // If cart is not set we want to go back to shop
    store.commit('cart/setExpired', true)
  }
  if (!store.getters['process/session']) {
    if (store.getters['process/checkoutPid']) {
      // Pull checkout on refresh
      await store.dispatch('process/getCheckout', store.getters['process/checkoutPid'])
    }
    if (!store.getters['process/session']) {
      store.commit('cart/setExpired', true)
    }
  }
}
