
/**
 * Copies a string of text to the user's clipboard
 * @param {string} content The content within the downloaded file
 */
async function copyToClipboard(content) {
  const activeEl = document.activeElement

  const textarea = document.createElement('textarea')
  textarea.value = content

  textarea.setAttribute('readonly', '') // Prevent keyboard from showing on mobile
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  textarea.style.fontSize = '12pt' // Prevent zooming on iOS

  document.body.append(textarea)
  textarea.select()
  document.execCommand('copy')
  textarea.remove()

  await activeEl && activeEl.focus()
  return true
}

export default {
  bind(el, binding, vnode) {
    const app = vnode.componentInstance ? vnode.componentInstance : vnode.context
    binding.handler = () => copyToClipboard(binding.value).then(() => {
      const label = vnode.componentOptions.propsData.label || ''
      app.$root.$toast.success(`${label} copied to your clipboard.`)
    })
    el.addEventListener('click', binding.handler)
    el.addEventListener('emit', binding.handler)
  },
  unbind(el, binding) {
    el.removeEventListener('click', binding.handler)
  }
}
