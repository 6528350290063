export default async function({ app, store }) {
  //During initial setup we check if cart exists, bind if it does, create if not
  const cartId = store.getters['cart/id']
  if (!cartId) {
    await store.dispatch('createCart').catch(e => {
      console.error('Create Cart Error: ', e)
      app.$toast.error('Create cart error...')
    })
  } else {
    await store.dispatch('bindCart', cartId).catch(e => {
      console.error('Bind Cart Error: ', e)
      app.$toast.error('Bind cart error...')
    })
  }
  // Setup a watcher to handle cart expiration
  store.watch(
    (state, getters) => {
      return getters['cart/instance']
    },
    (newCart, oldCart) => {
      if (!newCart.pid) {
        // @todo change this logic with new cart release, carts will no longer delete
        // Assume a cart delete during checkout updating is from a successful order
        if (app.$router?.currentRoute?.name === 'checkout-receipt' || !store.getters['cart/id'] || store.getters['process/checkoutUpdating']) {
          store.dispatch('createCart')
        } else if (oldCart.pid && oldCart.pid === store.getters['cart/id']) {
          // If cart was deleted on the backend we are still holding the old id in the store, which means we assume cart expired
          console.log('cart expired on watcher', { newCart, oldCart })
          store.commit('cart/setExpired', true)
        }
      } else if (newCart.expires_in !== oldCart.expires_in) {
        // New cart exists and expiration changed
        if (newCart.expires_in === null) {
          // Cart does not expire
          store.commit('cart/setExpiresAt', null)
        } else {
          // Convert cart expires_in to a local date time
          const expiresAt = (new Date()).getTime() + (newCart.expires_in * 1000)
          store.commit('cart/setExpiresAt', expiresAt)
        }
      }
    }
  )
}
