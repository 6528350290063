
import { mapGetters } from 'vuex'
import AuthCard from '@/components/ui/auth-card'

export default {
  name: 'shop-user-menu',
  components: { AuthCard },
  data() {
    return {
      showLanding: true,
      menu: false
    }
  },
  computed: {
    ...mapGetters({
      authed: 'auth/authed',
      user: 'auth/claims'
    })
  }
}
