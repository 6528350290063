
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'top-main-nav',
  computed: {
    ...mapGetters({
      authed: 'auth/authed',
      seller: 'seller',
      settings: 'settings',
      navDrawer: 'leftNavDrawer',
      shopSettings: 'shopSettings'
    }),
    drawer: {
      get() { return this.navDrawer },
      set(v) { this.setLeftNav(v) }
    },
    imageUrl() {
      return this.shopSettings?.about_me?.image_url
    },
    displayName() {
      return this.shopSettings?.display_name
    },
    links() {
      const $this = this
      const links = [
        {
          name: 'Privacy Policy',
          url: '/privacy',
          show: true
        },
        {
          name: 'Terms & Conditions',
          url: '/terms',
          show: this.settings?.sections.terms.show
        },
        {
          name: 'Return Policy',
          url: '/returns',
          show: this.settings?.sections.return_policy.show
        }
      ]
      if (this.settings?.customer_login?.enabled) {
        links.unshift(
          {
            name: 'Sign in',
            url: process.env.SSO_URL + `/?redirectTo=${window.location.origin}&sellerId=` + this.seller.id,
            action: () => $this.drawer = false,
            show: !this.authed
          }
        )
      }
      return links
    },
    menuItems() {
      const $this = this
      return [
        {
          title: 'Contact me',
          url: '/contact',
          action: () => $this.drawer = false
        }
      ]
    }
  },
  methods: {
    viewCart() {
      this.$store.commit('setCartDrawer', true)
      this.drawer = false
    },
    ...mapMutations({
      setLeftNav: 'setLeftNavDrawer'
    })
  }
}
