/**
 * Getters for Proxy module
 * imported in store/proxy.js
 */

export default {

  /**
  * Get cart items
  *
  * @returns {array}
  */
  items: ({ cart }) => cart?.items,

  /**
  * Get all cart items quantity
  * @param {object} state
  * @returns {string}
  */
  total: ({ session }) => session?.total,

  /**
  * Get all cart items quantity
  * @param {object} cart
  * @returns {string}
  */
  quantity: ({ cart }) => {
    const arr = []

    cart?.items?.reduce((acc, cur) => {
      const qty = cur.qty || cur.quantity
      return arr.push(qty)
    }, 0)

    return arr.reduce((a, b) => a + b, 0)
  },

  /**
  * Get cart id
  * @param {object} cart
  * @returns {object}
  */
  cartId: ({ cart }) => cart?.id,

  /**
  * Get session object
  * @param {object} session
  * @returns {object}
  */
  session: ({ session }) => session
}
