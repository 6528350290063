import moduleState from './cart/state'
import moduleMutations from './cart/mutations'
import moduleActions from './cart/actions'
import moduleGetters from './cart/getters'

export const state = moduleState

export const mutations = moduleMutations

export const getters = moduleGetters

export const actions = moduleActions
