export default async ({ store, app }) => {
  // Handle dark/light theme
  let systemDark = window.matchMedia('(prefers-color-scheme: dark)').matches

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    systemDark = e.matches
  })

  const userThemeChoice = store.getters.userThemeChoice
  const vuetifyThemes = app.vuetify.framework.theme.themes
  const appThemes = store.getters.settings.theme.themes

  vuetifyThemes.dark.primary = await appThemes.dark.primary
  vuetifyThemes.light.primary = await appThemes.light.primary

  let selection = ''
  if (userThemeChoice) {
    switch (userThemeChoice) {
      case 0:
        selection = store.getters.settings.theme.dark
        break

      case 1:
        selection = systemDark
        break

      case 2:
        selection = false
        break

      case 3:
        selection = true
        break
    }
  } else {
    selection = store.getters.settings?.theme?.dark
  }

  await store.commit('setDarkTheme', selection)

  app.vuetify.framework.theme.dark = await store.getters.theme.dark
}
