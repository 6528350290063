/**
 * Mutations for Cart module
 * imported in store/cart.js
 */
export default {
  /**
   * Sets the cart data instance
   * @param {object} state
   * @param {number} id
   */
  setId(state, id) {
    state.id = id ?? null
  },

  /**
   * Sets the cart data instance
   * @param {object} state
   * @param {object} instance
   */
  setInstance(state, instance) {
    state.instance = instance
    state.id = instance.id ?? null
  },

  /**
   * Clears the cart data instance
   * @param {object} state
   */
  clearInstance(state) {
    state.id = null
    state.instance = null
  },

  /**
   * Sets the cart expiration as millis from epoc
   * Calculate this by taking initial cart.expires_in on create and compare against local clock
   * @param {object} state
   * @param {number|null} expiresAt
   */
  setExpiresAt(state, expiresAt) {
    state.expiresAt = expiresAt
    if (state.expiresAt !== null && state.expiresAt <= (new Date())) {
      // Cart is expired if expiration date is less than now
      state.expired = true
    } else {
      state.expired = false
    }
  },

  /**
   * Sets the cart as expired
   * @param {object} state
   * @param {boolean} expired
   */
  setExpired(state, expired) {
    state.expired = expired
  },

  /**
   * Set cart updating
   * @param {object} state
   * @param {boolean} isUpdating
   */
  setCartUpdating(state, isUpdating) {
    state.cartUpdating = isUpdating
  },

  /**
   * Toggle the cart loading state
   * @param {object} state
   * @param {boolean} isLoading
   */
  toggleCartLoading(state, isLoading) {
    state.cartLoading = isLoading
  }
}
