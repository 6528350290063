/**
 * Actions for Proxy module
 * imported in store/proxy.js
 */

export default {
  /**********
   * CREATE
   * **********/

  /**
   * Action to create a checkout session
   * @param {object} payload
   * @returns {promise}
   */
  createCart({ commit, dispatch }, payload) {
    return this.$apiCart.$post('/carts', payload)
      .then(resp => {
        if (!resp) {
          throw Error
        }
        return resp
      }).catch(() => {})
  },

  /**
   * Action to create a checkout session
   * @param {object} payload
   * @returns {promise}
   */
  addCartItem({ rootGetters }, payload) {
    const cid = rootGetters['cart/id']
    try {
      return this.$apiCart.$post(`/carts/${cid}/lines`, [payload])
        .then(resp => {
          console.log('resp from addCartItem store', resp)
        })
    } catch (error) {
      console.error('add cart item error: ', error)
    }
  },

  /**********
   * READ
   * **********/

  /**
   * Action to get a checkout session
   * @param {number} id - id of the cart to get
   * @returns {promise}
   */
  async getCart({ state, commit, rootGetters }, id) {
    const pid = id || rootGetters.id
    await this.$apiCart.$get(`/carts/${pid}?expands[]=lines`)
      .then(cart => {
        if (!cart) {
          throw Error
        } else {
          commit('setCart', cart)
        }
      })
      .catch()
  },

  /**********
   * UPDATE
   * **********/
  /**
   * Action to update an item quantity in a cart
   *
   * @param {object} item
   * @returns {promise}
   */
  async updateQuantity({ dispatch }, item) {
    await this.$apiCart.patch(`/cartlines/${item.details.pid}`, {
      quantity: item.qty
    }).catch(() => {})
    await dispatch('getCart')
  }
}
