
import { mapGetters } from 'vuex'

export default {
  name: 'shop-footer',
  computed: {
    ...mapGetters({
      seller: 'seller',
      settings: 'settings',
      shopSettings: 'shopSettings'
    }),
    links() {
      return [
        {
          name: 'Privacy Policy',
          url: '/privacy',
          show: true
        },
        {
          name: 'Terms & Conditions',
          url: '/terms',
          show: this.settings.sections.terms.show
        },
        {
          name: 'Return Policy',
          url: '/returns',
          show: this.settings.sections.return_policy.show
        }
      ]
    }
  }
}
