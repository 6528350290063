/** Import Checkout module from local store/checkout.js */
import moduleMutations from './process/mutations'
import moduleActions from './process/actions'
import moduleGetters from './process/getters'

export const state = () => ({
  checkoutPid: null,
  session: null,
  payment: null,
  autoshipPlan: null,
  checkoutUpdating: null,
  paymentResult: null,
  paymanPaymentId: null,
  contactInfo: null
})

export const mutations = moduleMutations

export const getters = moduleGetters

export const actions = moduleActions
