/**
 * Mutations for Auth module
 * imported in store/auth.js
 */
export default {
  /**
   * Save the tenant info to state
   * @param {object} state
   * @param {object} authUser
   */
  setAuthUser(state, authUser) {
    const { uid, email, emailVerified, displayName, photoURL, tenantId, fullName, tenant } = authUser

    // Set each profile in profiles array
    if (authUser.providerData) {
      const profiles = []
      authUser.providerData.forEach(profile => {
        const { providerId, uid, displayName, email, photoURL } = profile
        profiles.push({ providerId, uid, displayName, email, photoURL })
      })
      state.profiles = profiles
    }

    state.authUser = { uid, email, emailVerified, displayName, photoURL, tenantId, fullName, tenant }
  },

  /**
   * Save the tenant info to state
   * @param {object} state
   * @param {object} user
   */
  setUser(state, user) {
    state.user = user
  },

  /**
   * Save the tenant info to state
   * @param {object} state
   * @param {object} tenantInfo
   */
  setTenantInfo(state, tenantInfo) {
    state.tenantInfo = tenantInfo
  },

  /**
   * Toggle authed state
   * @param {object} state
   * @param {object} payload
   */
  setAuthed(state, payload) {
    state.authed = payload
  },

  /**
   * Toggle authing state
   * @param {object} state
   * @param {object} payload
   */
  setAuthing(state, payload) {
    state.authing = payload
  },

  /**
   * Toggle authing message
   * @param {object} state
   * @param {object} payload
   */
  setAuthingMessage(state, payload) {
    state.authingMessage = payload
  },

  /**
   * Logout authed user
   * @param {object} state
   */
  logoutAuthUser(state) {
    state.user = null
    state.authUser = null
    state.authing = false
    state.authed = false
    state.token = null
    state.profiles = []
    state.savedPaymentInfo = null
    state.savedAddresses = {}
  },

  /**
   * Set the authed users bearer token
   * @param {object} state
   * @param {string} token
   */
  setToken(state, token) {
    state.token = token
    // state.authed = true
  },

  /**
   * Set the authed users claims from token
   * @param {object} state
   * @param {object} claims
   */
  setClaims(state, claims) {
    state.claims = claims
    // state.authed = true
  },

  /**
   * Set the authed users photo url
   * @param {object} state
   * @param {string} url
   */
  setUserPhotoURL(state, url) {
    state.user.photoURL = url
  },

  /**
   * Set the redirect url
   * @param {object} state
   * @param {string} url
   */
  setRedirectUrl(state, url) {
    state.redirectUrl = url
  },

  /**
   * Set the redirect url
   * @param {object} state
   * @param {object} payload
   */
  setNewUser(state, payload) {
    state.newUser = payload
  },

  /**
   * Set the authed users saved addresses
   * @param {object} state
   * @param {object} savedPaymentInfo
   */
  setSavedPaymentInfo(state, savedPaymentInfo) {
    state.savedPaymentInfo = savedPaymentInfo
  }
}
