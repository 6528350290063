import { vuexfireMutations } from 'vuexfire'
import { strings } from '@/config'

export const state = () => ({
  theme: {
    user: null,
    dark: null
  },
  isLegacy: true,
  globalModal: {
    open: false,
    title: null,
    url: null,
    content: null
  },
  leftNavDrawer: false,
  mobileDrawer: false,
  cartDrawer: false,
  seller: {},
  settings: {},
  store_settings: {},
  user_settings: {},
  orderAssistID: null,
  receipt: {}
})

export const actions = {
  /**********
   * CREATE
   * **********/

  /**
   * Submit the contact form
   * @param {object} payload
   * @returns {promise}
   */
  async submitContactForm(payload) {
    const message = await this.$apiCore.post('/core/contact', payload).catch(e => console.log(e))
    return message
  },

  /**
   * Save state to local storage
   */
  persistState({ state }) {
    const persistedData = {
      auth: { token: state.auth.token },
      cart: { id: state.cart.id },
      receipt: state.receipt,
      process: {
        expires: state.process.expires,
        checkoutPid: state.process.checkoutPid,
        paymanPaymentId: state.process.paymanPaymentId,
        contactInfo: state.process.contactInfo
      }
    }

    window.localStorage.setItem(strings.VUEX_LOCAL_STORAGE_KEY, JSON.stringify(persistedData))
  },

  /**
   * upload a new image
   * @param {object} formData
   * @returns {promise}
   */
  async uploadImage({ dispatch }, formData) {
    const image = await this.$axios({
      method: 'put',
      url: `/locations/${this.locationId}/brand_logo`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    })
    return image
  },

  /**
   * Create a new cart with normalized shop info
   * @returns {promise}
   */
  async createCart({ dispatch, commit, getters }) {
    commit('cart/toggleCartLoading', true)
    /* eslint-disable camelcase */
    const seller = getters.seller
    const settings = getters.settings
    const company_pid = settings.company.pid
    const seller_pid = seller.pid
    const seller_type_id = seller.role === 'rep' ? seller.seller_type_id : 1
    const inventory_user_pid = seller_type_id === 1 ? company_pid : seller_pid
    const site_cart_type = seller_type_id === 1 ? 'affiliate' : 'retail'

    const payload = {
      inventory_user_pid,
      seller_pid,
      type: site_cart_type
    }

    await dispatch('cart/createCart', payload)
  },

  /**********
   * READ
   * **********/
  /**
   * Action to get shop info
   * @param {string} storeName - name of the shop to get info for (seller.public_id)
   * @returns {promise}
   */
  async info({ commit }, storeName) {
    const resp = await this.$apiUsers.$get(`/store-info/${storeName}`)
    if (resp.store_settings.about_me) {
      resp.store_settings.about_me = JSON.parse(resp.store_settings.about_me)
    }
    await commit('setInfo', resp)
  },

  /**
   * Action to bind the shop cart
   * @param {string} id
   * @returns {promise}
   */
  async bindCart({ commit, dispatch }, id) {
    await dispatch('cart/bindCart', id)
      .catch(e => {
        console.log('bind cart failed, create new', e)
        dispatch('createCart')
      })
      .finally(() => commit('cart/toggleCartLoading', false))
  },

  /**
   * Generate a new nexio payment token
   * @returns {string}
   */
  generateNexioToken({ getters }) {
    const cssPath = window.location.origin + '/styles/nexio-styles.css'

    const paymentSettings = getters.settings.payment

    const payload = {
      teamId: paymentSettings.team,
      showBilling: false,
      gatewayType: 'nexiopay'
    }

    if (paymentSettings.css) {
      payload.cssFileUrl = cssPath
    }

    return this.$axios.$post(paymentSettings.payman + '/transactions/generate-form-token', payload)
  }
}

export const mutations = {
  // import vuexfireMutations
  ...vuexfireMutations,

  /**
   * Set order assist ID
   * @param {object} state
   * @param {string} id
   */
  setOrderAssistID(state, id) {
    state.orderAssistID = id
  },

  /**
   * Set the receipt info after payment
   * @param {object} state
   * @param {object} receipt
   */
  setReceipt(state, receipt) {
    state.receipt = receipt
  },

  /**
   * Clear receipt data
   * @param {object} state
   */
  clearReceipt(state) {
    state.receipt = null
  },

  /**
   * Set theme
   * @param {object} state
   * @param {object} theme
   */
  setTheme(state, theme) {
    state.theme = theme
  },

  /**
   * Set user theme choice
   * @param {object} state
   * @param {string} choice
   */
  setUserThemeChoice(state, choice) {
    state.theme.user = choice
  },

  /**
   * Set if theme is dark
   * @param {object} state
   * @param {boolean} toggle
   */
  setDarkTheme(state, toggle) {
    state.theme.dark = toggle
  },

  /**
   * Set leftNavDrawer state
   * @param {object} state
   * @param {boolean} toggle
   */
  setLeftNavDrawer(state, toggle) {
    state.leftNavDrawer = toggle
  },

  /**
   * Set cartDrawer state
   * @param {object} state
   * @param {boolean} toggle
   */
  setCartDrawer(state, toggle) {
    state.cartDrawer = toggle
  },

  /**
   * Set mobileDrawer state
   * @param {object} state
   * @param {boolean} toggle
   */
  setMobileDrawer(state, toggle) {
    state.mobileDrawer = toggle
  },

  /**
   * Set store settings state
   * @param {object} state
   * @param {object} settings
   */
  setInfo(state, settings) {
    state.settings = settings.settings
    state.seller = settings.seller
    state.store_settings = settings.store_settings
    state.user_settings = settings.user_settings
  },

  /**
   * Set user settings state
   * @param {object} state
   * @param {object} settings
   */
  setUserInfo(state, settings) {
    state.user_settings = settings
  }
}

export const getters = {
  /**
  * Check if proxy
  * @returns {boolean}
  */
  isLegacy: ({ isLegacy }) => isLegacy,

  /**
  * Get the theme object
  * @returns {object}
  */
  theme: ({ theme }) => theme,

  /**
  * Get the user theme choice state
  * @returns {boolean}
  */
  userThemeChoice: ({ theme }) => theme.user,

  /**
  * Get darkTheme state
  * @returns {boolean}
  */
  darkTheme: ({ theme }) => theme.dark,

  /**
  * Get light theme primary color
  * @returns {string}
  */
  lightBrandColor: ({ theme }) => theme.themes.light,

  /**
  * Get dark theme primary color
  * @returns {string}
  */
  darkBrandColor: ({ theme }) => theme.themes.light,

  /**
  * Get leftNavDrawer state
  * @returns {boolean}
  */
  leftNavDrawer: ({ leftNavDrawer }) => leftNavDrawer,

  /**
  * Get cartDrawer state
  * @returns {boolean}
  */
  cartDrawer: ({ cartDrawer }) => cartDrawer,

  /**
  * Get mobileDrawer state
  * @returns {boolean}
  */
  mobileDrawer: ({ mobileDrawer }) => mobileDrawer,

  /**
  * Get globalModal state
  * @returns {boolean}
  */
  globalModal: ({ globalModal }) => globalModal,

  /**
  * Get shop settings
  * @returns {object}
  */
  settings: ({ settings }) => settings,

  /**
  * Get shop owner/seller
  * @returns {object}
  */
  seller: ({ seller }) => seller,

  /**
  * Get store_settings
  * @returns {object}
  */
  // eslint-disable-next-line camelcase
  shopSettings: ({ store_settings }) => store_settings,

  /**
  * Get store_settings
  * @returns {object}
  */
  // eslint-disable-next-line camelcase
  userSettings: ({ user_settings }) => user_settings,

  /**
  * Get order assist ID
  * @returns {object}
  */
  prettyUserName: ({ auth }) => {
    return auth.claims.fullName
  },

  /**
  * Get order assist ID
  * @returns {object}
  */
  orderAssistID: ({ orderAssistID }) => orderAssistID,

  /**
  * Get the order info
  * @returns {object}
  */
  order: ({ order }) => order,

  /**
  * Get the receipt info
  * @returns {object}
  */
  receipt: ({ receipt }) => receipt,

  /**
  * Is this order a Nexio order?
  * @param {object} state
  * @returns {boolean}
  */
  gateway: ({ settings }) => settings.payment.gateway
}
