
/* eslint-disable no-param-reassign */
export default {
  computed: {
    letterFill() {
      const color = this.$store.getters.settings.theme.dark
        ? this.$store.getters.settings.theme.themes.dark
        : this.$store.getters.settings.theme.themes.light
      return color
    },
    borderColor() {
      return this.shadeColor(this.letterFill.error, -40)
    }
  },

  methods: {
    shadeColor(col, amt) {
      col = col.replace(/^#/, '')
      if (col.length === 3) { col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2] }

      let [r, g, b] = col.match(/.{2}/g);
      ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

      r = Math.max(Math.min(255, r), 0).toString(16)
      g = Math.max(Math.min(255, g), 0).toString(16)
      b = Math.max(Math.min(255, b), 0).toString(16)

      const rr = (r.length < 2 ? '0' : '') + r
      const gg = (g.length < 2 ? '0' : '') + g
      const bb = (b.length < 2 ? '0' : '') + b

      return `#${rr}${gg}${bb}`
    }
  }
}
