/**
 * Actions for Auth module
 * imported in store/auth.js
 */

export default {
  /**********
   * CREATE
   * **********/

  /**
   * Action to login with a username and password form
   * @param {object} payload - username and password
   * @returns {promise}
   */
  async login(payload) {
    await this.$fire.auth.signInWithEmailAndPassword(payload.email, payload.password)
  },

  /**
   * Firebase Action on auth state change
   * @returns {promise}
   */
  onAuthStateChangedAction: async ({ commit }, { authUser, claims }) => {
    if (authUser) {
      await commit('setAuthUser', authUser)
      await commit('setClaims', claims)
      await commit('setAuthed', true)
    }
  },

  /**
   * Action to authenticate a user with a custom token
   * @param {string} token - exchange Google token for OPSY JWT
   * @returns {promise}
   */
  async handleRefreshToken({ dispatch }, token) {
    const { data } = await this.$apiAuth.get('/token/refresh?schema=legacy', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return await dispatch('signInWithCustomToken', data.token)
  },

  /**
   * Action to signin with a custom token
   * @param {object} store
   * @param {string} token
   * @returns {promise}
   */
  async signInWithCustomToken(store, token) {
    return await this.$fire.auth.signInWithCustomToken(token).catch(e => console.log(e))
  },

  /**
   * Action to authenticate a user with a provider such as Google, Facebook etc.
   * @param {object} provider - provider object to auth to
   * @returns {promise}
   */
  async loginWithProvider({ commit }, provider) {
    await this.$fire.auth.signInWithPopup(provider)
      .then(r => {
        const token = r.credential.accessToken
        commit('setToken', token)
        this.$toast.success('Logged in', { icon: 'mdi-check' })
      }).catch(e => this.$toast.error(e.message))
  },

  /**
   * Action to register a new user
   * @param {object} payload - username and password
   * @returns {promise}
   */
  register(payload) {
    return this.$fire.auth.createUserWithEmailAndPassword(payload.email, payload.password)
  },

  /**
   * Action to reset a users password
   * @param {string} email
   * @returns {promise}
   */
  resetPassword(email) {
    return this.$fire.auth.sendPasswordResetEmail(email)
      .then(() => this.$toast.success('Password reset email sent.', { icon: 'mdi-check' }))
      .catch(e => console.error(e.message))
  },

  /**********
   * READ
   * **********/

  /**
   * Action to get tenant info
   * @param {string} slug
   * @returns {promise}
   */
  async getTenantInfo({ commit }, slug) {
    // const { data } = await this.$apiAuth.$get(`/tenants?filter[slug]=${slug}&include=domains`)
    const { data } = await this.$apiAuth.$get(`/tenants?filter[wildcard]=${slug}&include=domains`)

    if (!data.length) {
      throw new Error('No tenant exists.')
    }

    // Set tenantId for auth
    this.$fire.auth.tenantId = data[0].google_cloud_tenant_id

    // Filter array and use tenat_id
    const tenantHost = data[0].domains.data.filter(d => d.service_name === 'core')

    await commit('setTenantInfo', { ...data[0], tenantHost: tenantHost[0].host })
  },

  /**
   * Get signin
   * @param {string} email
   * @returns {promise}
   */
  async userExistsCheck(email) {
    const user = await this.$fire.auth.fetchSignInMethodsForEmail(email)
    return user
  },

  /**
   * Get auth
   * @returns {promise}
   */
  async getAuth() {
    const user = await this.$fire.auth()
    return user
  },

  /**
   * Action to get info for a logged in user from controlpad
   * @returns {promise}
   */
  async getUser() {
    const token = await this.$fire.auth.currentUser.getIdToken()

    return await this.$apiUsers.$get(`/users/${this.$utils.parseJwt(token).user.pid}?addresses=true`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },

  /**
   * Action to get shipping info for a logged in user
   * @param {object} state
   * @returns {promise}
   */
  async getSavedPaymentInfo({ commit }) {
    const token = await this.$fire.auth.currentUser.getIdToken()

    const savedPaymentInfo = await this.$apiUsers.$get(`/users/${this.$utils.parseJwt(token).user.pid}/card-token`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (savedPaymentInfo && savedPaymentInfo.user_id) {
      commit('setSavedPaymentInfo', savedPaymentInfo)
      return savedPaymentInfo
    } else {
      return null
    }
  },

  /**********
   * DELETE
   * **********/

  /**
   * Action to logout
   * @param {string} redirectUrl
   * @returns {promise}
   */
  async logout({ commit }, redirectUrl) {
    await this.$fire.auth.signOut()
      .then(() => {
        commit('logoutAuthUser')
        this.$toast.success('Logged out', { icon: 'mdi-check' })
        if (redirectUrl) {
          this.$router.push(redirectUrl)
        }
      })
      .catch(error => this.$toast.error(error.message))
  }
}
