export default ({
  app,
  $apiCheckout,
  $bugsnag,
  store
}) => {
  // default error handler
  const checkoutErrorHandler = error => {
    console.log('checkout error handle', error)
    console.log('response', error.response)
    const code = parseInt(error.response && error.response.status, 10)
    const response = error.response

    let errorMessage = 'Unexpected error'

    if (isNaN(code)) {
      app.$toast.error(error, { icon: 'error', duration: 10000 })
      return
    }

    switch (code) {
      case 500:
        errorMessage = '*** 500 Internal Server Error. API may be down. ***'
        break

      case 400:
        errorMessage = Object.values(response.data)[0]
        if ($bugsnag) {
          $bugsnag.notify(error, function(event) {
            event.context = 'server-bad-request'
            event.addMetadata('response', response)
          })
        }
        break

      case 401:
        // app.store.dispatch('auth/logout')
        errorMessage = response.statusText
        break

      case 403:
        // app.store.dispatch('auth/logout')
        errorMessage = response.statusText
        break

      case 404:
        // Assume cart cart expired
        errorMessage = 'Cart expired'
        console.log('cart expired from checkout 404')
        store.commit('cart/setExpired', true)
        break

      case 422:
        if (response.data.message) {
          console.log('using data message:', response.data.message)
          errorMessage = response.data.message
        } else {
          console.log('no data message')
          errorMessage = Object.values(response.data)[0]
        }
        switch (response.data.result_code) {
          case 5:
            // Payment declined
            store.commit('process/setPayment', null)
            break
          case 6:
            // Shipping address not set on process, not likely to happen
            // Do nothing for now
            break
          case 99:
          default:
            // Unexpected error
            if ($bugsnag) {
              $bugsnag.notify(error, function(event) {
                event.context = 'server-validation-error'
                event.addMetadata('response', response)
              })
            }
            break
        }
        break
      case 423:
        // Checkout is locked for processing
        errorMessage = response.data
        break
      default:
        errorMessage = response
        break
    }
    app.$toast.error(errorMessage, { icon: 'alert', duration: 10000 })
  }
  // checkout api errors
  $apiCheckout.onError(err => checkoutErrorHandler(err))
}
