/**
 * Getters for Shop module
 * imported in store/shop.js
 */

export default {
  /**
  * Get cart id
  * @param {object} state
  * @returns {string}
  */
  id: ({ id }) => {
    return id
  },

  /**
  * Get cart instance
  * @param {object} state
  * @returns {object}
  */
  instance: ({ instance }) => instance || {},

  /**
  * Get cart lines
  * @param {object} state
  * @returns {array}
  */
  lines: ({ instance }) => instance ? instance.lines : [],

  /**
  * Get total cart line quantity
  * @param {object} state
  * @returns {array}
  */
  quantity: ({ instance }) => {
    const processedItems = instance && instance.lines || []
    let qty = 0

    for (let i = 0; i < processedItems.length; i++) {
      qty += parseInt(processedItems[i].quantity)
    }

    return qty
  },

  /**
  * Get cart loading state
  * @param {object} state
  * @returns {boolean}
  */
  cartLoading: ({ cartLoading }) => cartLoading,

  /**
   * Get cart updating
   * @param {object} state
   * @returns {boolean}
   */
  cartUpdating: ({ cartUpdating }) => cartUpdating,

  /**
  * Get cart expiresAt
  * @param {object} state
  * @returns {number}
  */
  expiresAt: ({ expiresAt }) => expiresAt,

  /**
  * Get cart expired
  * @param {object} state
  * @returns {boolean}
  */
  expired: ({ expired }) => expired
}
