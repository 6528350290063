import { strings } from '@/config'

export default async ({ store }) => {
  // restore state
  try {
    let savedState = window.localStorage.getItem(strings.VUEX_LOCAL_STORAGE_KEY)
    if (savedState) {
      savedState = JSON.parse(savedState)
      // When using store.replaceState(newState): If state is not fully hydrated (deep merge), variables will be undefined in state and break getters
      // Only pulling specific values and setting through commit for now

      if (savedState.receipt) {
        await store.commit('setReceipt', savedState.receipt)
      }
      if (savedState.auth && savedState.auth.token) {
        await store.commit('auth/setToken', savedState.auth.token)
      }
      if (savedState.cart && savedState.cart.id) {
        await store.commit('cart/setId', savedState.cart.id)
      }

      if (!savedState?.process?.expires || savedState.process.expires > Date.now()) {
        // Only load checkout data when not expired
        if (savedState?.process?.checkoutPid) {
          await store.commit('process/setCheckoutPid', savedState.process.checkoutPid)
        }
        if (savedState?.process?.contactInfo) {
          await store.commit('process/setContactInfo', savedState.process.contactInfo)
        }
        if (savedState?.process?.paymanPaymentId) {
          await store.commit('process/setPaymanPaymentId', savedState.process.paymanPaymentId)
        }
        if (savedState?.process?.expires) {
          await store.commit('process/setExpires', savedState.process.expires)
        }
      }
    }
  } catch (err) {
    console.error(err)
  }

  // Subscribe to store changes
  store.subscribe((mutation, state) => {
    store.dispatch('persistState')
  })
}
