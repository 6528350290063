
import { VProgressCircular, VOverlay } from 'vuetify/lib'

export default {
  components: { VProgressCircular, VOverlay },
  props: {
    // overlay props
    zIndex: {
      type: [String, Number],
      default: 1
    },
    overlayColor: {
      type: String,
      default: ''
    },
    overlayOpacity: {
      type: [String, Number],
      default: 0.46
    },
    absolute: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: true
    },

    // loader props
    size: {
      type: [String, Number],
      default: 32
    },
    width: {
      type: [String, Number],
      default: 1
    },
    color: {
      type: String,
      default: 'primary'
    },
    fillHeight: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  computed: {
    darkTheme() {
      return this.dark || this.$vuetify.theme.isDark
    },
    lightTheme() {
      return this.light || !this.$vuetify.theme.isDark
    },
    setOverlayColor() {
      const themeHue = this.darkTheme ? '#212121' : '#ffffff'
      return this.overlayColor || themeHue
    }
  }
}
