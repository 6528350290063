

export default {
  props: {
    firstLink: {
      type: Object,
      default() {
        return { to: '/', text: 'Home', external: false }
      }
    },
    dividerIcon: {
      type: String,
      default: ''
    },
    dividerString: {
      type: String,
      default: '/'
    }
  },
  computed: {
    firstBreadLink() {
      return { to: '/checkout', text: 'Cart', external: false }
    },
    crumbs() {
      const breadcrumbs = [
        {
          to: '/checkout',
          text: 'Checkout',
          disabled: true
        },
        {
          to: '/checkout/receipt',
          text: 'Receipt',
          disabled: true
        }
      ]

      return [this.firstBreadLink, ...breadcrumbs]
    }
  }
}
