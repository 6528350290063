// Auth plugin to handle preloading users address and payment info
export default async ({ store, app, route }) => {
  let authed = store.getters['auth/authed']
  if (!authed) {
    const token = route.query.token
    if (token) {
      const refreshResult = await store.dispatch('auth/handleRefreshToken', token)
      authed = refreshResult.user && refreshResult.user.refreshToken
    }
  }

  /* eslint-disable camelcase */
  if (authed) {
    const user = await store.dispatch('auth/getUser')
    if (user.shipping_address) {
      delete user.shipping_address.label
    }
    if (user.billing_address) {
      delete user.billing_address.label
    }
    await store.commit('auth/setUser', user)

    await store.dispatch('auth/getSavedPaymentInfo')
  }
}
