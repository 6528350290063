import moduleMutations from './events/mutations'
import moduleActions from './events/actions'
import moduleGetters from './events/getters'

export const state = () => ({
  all: null
})

export const mutations = moduleMutations

export const getters = moduleGetters

export const actions = moduleActions
