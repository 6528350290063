/**
 * Getters for Auth module
 * imported in store/auth.js
 */

export default {

  /**
  * Get auth status
  * @returns {boolean}
  */
  authed: ({ authed }) => authed,

  /**
  * Get authing status
  * @returns {boolean}
  */
  authing: ({ authing }) => authing,

  /**
  * Get authingMessage
  * @returns {string}
  */
  authingMessage: ({ authingMessage }) => authingMessage,

  /**
  * Get tenantInfo
  * @returns {object}
  */
  tenantInfo: ({ tenantInfo }) => tenantInfo,

  /**
  * Get authed user as controlpad data
  * @returns {object}
  */
  user: ({ user }) => user,

  /**
  * Get new user
  * @returns {object}
  */
  newUser: ({ newUser }) => newUser,

  /**
  * Get authed users token
  * @returns {string}
  */
  token: ({ token }) => token,

  /**
  * Get authed users claims
  * @returns {object}
  */
  claims: ({ claims }) => claims,

  /**
  * Get redirect URL
  * @returns {string}
  */
  redirectUrl: ({ redirectUrl }) => redirectUrl,

  /**
  * Get saved payment info
  * @param {object} state
  * @returns {string}
  */
  savedPaymentInfo: ({ savedPaymentInfo }) => savedPaymentInfo
}
