
import { mapGetters, mapMutations } from 'vuex'
import AuthCard from '@/components/ui/auth-card'

export default {
  name: 'top-main-nav',
  components: { AuthCard },
  data() {
    return {
      showLanding: true
    }
  },
  computed: {
    ...mapGetters({
      authed: 'auth/authed',
      user: 'auth/claims',
      mobileDrawer: 'mobileDrawer',
      shopSettings: 'shopSettings',
      settings: 'settings'
    }),
    customerLoginEnabled() {
      return this.settings?.customer_login?.enabled
    },
    drawer: {
      get() { return this.mobileDrawer },
      set(v) { this.setMobileDrawer(v) }
    },
    imageUrl() {
      return this.shopSettings?.about_me?.image_url
    },
    displayName() {
      return this.shopSettings?.display_name
    }
  },
  methods: {
    ...mapMutations({
      setMobileDrawer: 'setMobileDrawer'
    }),
    async logout() {
      await this.$store.dispatch('auth/logout')
    },
    async fbLogin() {
      const facebook = new this.$fireModule.auth.FacebookAuthProvider()
      await this.$store.dispatch('auth/loginWithProvider', facebook)
    },
    async googleLogin() {
      const google = new this.$fireModule.auth.GoogleAuthProvider()
      await this.$store.dispatch('auth/loginWithProvider', google)
    }
  }
}
