
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'cart-card',

  props: {
    line: [Object],
    checkoutMode: [Boolean]
  },

  data() {
    return {
      updatingQuantity: false
    }
  },

  computed: {
    ...mapGetters({
      cartUpdating: 'cart/cartUpdating',
      cartLoading: 'cart/cartLoading'
    }),
    item() {
      return this.line.items[0]
    },

    imageUrl() {
      return this.item.img_url
    },

    quantity: {
      get() { return this.line.quantity },
      set() {}
    }
  },

  methods: {
    ...mapActions({
      updateQuantity: 'cart/updateQuantity'
    }),
    ...mapMutations({
      setCartUpdating: 'cart/setCartUpdating'
    }),

    limitChars(string, limit) {
      if (string.length > limit) {
        return string.substring(0, limit) + '...'
      }
      return string
    },

    async changeQty(quantity) {
      if (this.updatingQuantity) {
        return
      }
      this.updatingQuantity = true
      this.setCartUpdating(true)

      const payload = {
        pid: this.line.pid,
        quantity
      }

      await this.updateQuantity(payload)

      this.$emit('quantity-update', payload)
      this.updatingQuantity = false
      this.setCartUpdating(false)
    },

    async blurQuantity(e) {
      const qty = Number(e.target.value)
      const sameQty = qty === this.quantity

      if (sameQty) { return }
      if (qty === 0) {
        this.deleteLine()
        return
      }

      await this.changeQty(qty)
    },

    async addQuantity() {
      await this.changeQty(this.quantity + 1)
    },

    async removeQuantity() {
      await this.changeQty(this.quantity - 1)
    },

    async deleteLine() {
      this.updatingQuantity = true
      this.setCartUpdating(true)
      await this.$store.dispatch('cart/deleteItem', this.line)
      this.updatingQuantity = false
      this.setCartUpdating(false)
    }
  }
}
