/**
 * Mutations for Proxy module
 * imported in store/proxy.js
 */
export default {
  /**
   * Sets the cart
   * @param {object} state
   * @param {object} payload
   */
  setCart(state, payload) {
    state.cart.data = payload
  },

  /**
   * Sets the checkout session
   * @param {object} state
   * @param {object} session
   */
  setSession(state, session) {
    state.session = session
  },

  /**
   * Clear checkout and store info
   * @param {object} state
   */
  clearCheckout(state) {
    state.cart = { id: null, items: [] }
    state.session = null
  }
}
