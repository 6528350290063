import { strings } from '~/config'
export default ({ $axios, $config }, inject) => {
  // default settings for instance
  const defaultOptions = {}

  // create a new instance
  const createInstance = opts => {
    return $axios.create({ ...defaultOptions, ...opts })
  }

  // create and inject axios instance for inventory API
  const apiInventory = createInstance({ baseURL: process.env.INVENTORY_API_URL })
  inject('apiInventory', apiInventory)

  // create and inject axios instance for cart API
  const apiCart = createInstance({ baseURL: process.env.CARTS_API_URL })
  inject('apiCart', apiCart)

  // create and inject axios instance for events API
  const apiEvents = createInstance({ baseURL: process.env.EVENTS_API_URL })
  inject('apiEvents', apiEvents)

  // create and inject axios instance for users API
  const apiUsers = createInstance({ baseURL: process.env.USERS_API_URL })
  inject('apiUsers', apiUsers)

  // create and inject axios instance for core API
  const apiCore = createInstance({ baseURL: process.env.CORE_API_URL })
  inject('apiCore', apiCore)

  // create and inject axios instance for auth API
  const apiCloudAuth = createInstance({ baseURL: process.env.CLOUD_AUTH_API_URL })
  inject('apiAuth', apiCloudAuth)

  // create and inject axios instance for events API
  const apiCheckout = createInstance({ baseURL: strings.CHECKOUT_API_URL })
  inject('apiCheckout', apiCheckout)
}
