
import ErrorGraphic from '@/components/ui/404-svg'

export default {
  name: 'error-layout',
  components: { ErrorGraphic },
  computed: {
    logoImage() {
      return this.$store.getters.settings?.images?.back_office_logo || ''
    }
  }
}
