export default async function({ app, store }) {
  const hostname = process.env.DP_TENANT || new URL(window.location.href).hostname
  // get tenant info
  await store.dispatch('auth/getTenantInfo', hostname)
    .catch(e => console.error('*** SET CORE SERVICE NAME ON DOMAIN ***', e))

  // Set tenantId for Google
  const tenant = store.getters['auth/tenantInfo']
  app.$fire.auth.tenantId = await tenant.google_cloud_tenant_id

  // set core API base url
  // const coreTenant = tenant.domains.data.filter(d => d.service_name === 'core')[0]
  // app.$apiCore.defaults.baseURL = `${window.location.protocol}//${coreTenant.host}`

  // set core API base url
  const coreTenant = tenant.domains.data.filter(d => d.service_name === 'core')[0]
  app.$apiCore.setBaseURL(`${window.location.protocol}//${coreTenant.host}`)
}
