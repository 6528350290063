/**
 * Getters for checkout module
 * imported in store/checkout.js
 */

export default {

  /**
  * Get cart items
  *
  * @param {object} state
  * @returns {array}
  */
  items: ({ session }) => session.lines,

  /**
  * Get total item quantities from checkout lines
  * @param {object} state
  * @returns {number}
  */
  itemQuantity: ({ session }) => {
    const lines = session && session.lines || []
    let qty = 0

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].bundle_id !== null) {
        lines[i].items.foreach(item => qty = qty + (item.quantity * lines[i].quantity))
      } else if (lines[i].item_id !== null) {
        qty += parseInt(lines[i].quantity)
      }
    }

    return qty
  },

  /**
  * Get all cart items quantity
  * @param {object} state
  * @returns {string}
  */
  total: ({ session }) => session.total,

  /**
  * Get the checkout pid
  * @param {string} state
  * @returns {string}
  */
  checkoutPid: ({ checkoutPid, session }) => checkoutPid || session?.pid || null,

  /**
  * Get the checkout autoship plan
  * @param {object} state
  * @returns {object}
  */
  autoshipPlan: ({ autoshipPlan }) => autoshipPlan,

  /**
  * Get session object
  * @param {object} state
  * @returns {object}
  */
  session: ({ session }) => session,

  /**
   * Get checkoutUpdating
   * @param {object} state
   * @returns {boolean}
   */
  checkoutUpdating: ({ checkoutUpdating }) => checkoutUpdating,

  /**
  * Get session payment
  * @param {object} state
  * @returns {object}
  */
  payment: ({ payment }) => payment,

  /**
   * Get payman payment id
   * @param {object} state
   * @returns {string}
   */
  paymanPaymentId: ({ paymanPaymentId }) => paymanPaymentId,

  /**
  * Get contact info
  * @param {object} state
  * @returns {object}
  */
  contactInfo: ({ contactInfo }) => contactInfo,

  /**
   * Get session paymentResult
   * @returns {object}
   */
  paymentResult: ({ paymentResult }) => paymentResult,

  /**
   * Get session expires
   * @returns {number|null}
   */
  expires: ({ expires }) => expires
}
