/*** Axios plugin settings */
export default ({
  app,
  $apiUsers,
  $apiEvents,
  $apiInventory,
  $apiCore,
  $bugsnag,
  query,
  store
}) => {
  // default error handler
  const defaultError = error => {
    console.log('axios error handle', error)
    const code = parseInt(error.response && error.response.status, 10)
    const response = error.response
    let errorMessage = '*** 400 Bad Request ***'

    if (isNaN(code)) {
      app.$toast.error(error, { icon: 'error', duration: 10000 })
      return
    }

    switch (code) {
      case 500:
        errorMessage = '*** 500 Internal Server Error. API may be down. ***'
        break

      case 400:
        errorMessage = Object.values(response.data)[0]
        if ($bugsnag) {
          $bugsnag.notify(error, function(event) {
            event.context = 'server-bad-request'
            event.addMetadata('response', response)
          })
        }
        break

      case 401:
        // app.store.dispatch('auth/logout')
        errorMessage = response.statusText
        break

      case 403:
        // app.store.dispatch('auth/logout')
        errorMessage = response.statusText
        break

      case 404:
        errorMessage = response.statusText
        break

      case 422:
        if (response.data.message) {
          errorMessage = response.data.message
        } else {
          errorMessage = Object.values(response.data)[0]
        }
        if ($bugsnag) {
          $bugsnag.notify(error, function(event) {
            event.context = 'server-validation-error'
            event.addMetadata('response', response)
          })
        }
        break

      default:
        errorMessage = response
        break
    }
    app.$toast.error(errorMessage, { icon: 'alert', duration: 10000 })
  }

  // users api errors
  $apiUsers.onError(err => defaultError(err))

  // events api errors
  $apiEvents.onError(err => defaultError(err))

  // inventory api errors
  $apiInventory.onError(err => defaultError(err))

  // core api errors
  $apiCore.onError(err => defaultError(err))
}
