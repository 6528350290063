
export default {
  name: 'opsy-footer',

  computed: {
    shopSettings() {
      return this.$store.getters.settings
    },
    shopOwnerName() {
      return this.shopSettings.company.name
    },

    links() {
      return [
        {
          name: 'terms',
          url: this.shopSettings.urls.terms
        },
        {
          name: 'terms',
          url: this.shopSettings.urls.terms
        }
      ]
    },

    thisYear() {
      return new Date().getFullYear()
    },

    ccTypes() {
      return [
        {
          name: 'visa',
          img: 'visa.png'
        },
        {
          name: 'mastercard',
          img: 'mastercard.png'
        },
        {
          name: 'discover',
          img: 'discover.png'
        },
        {
          name: 'amex',
          img: 'Amex.png'
        }
      ]
    }
  },

  methods: {
    imgSrc(type) {
      const base = process.env.ROUTER_BASE === '/' ? '' : process.env.ROUTER_BASE
      return `${base}/img/cc/light/${type.img}`
    }
  }

}
