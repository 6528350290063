
import { mapGetters, mapMutations } from 'vuex'
import UserMenu from '@/components/ui/menus/user'

export default {
  name: 'top-main-nav',

  components: { UserMenu },

  computed: {
    ...mapGetters({
      isLegacy: 'isLegacy',
      authed: 'auth/authed',
      leftNavDrawer: 'leftNavDrawer',
      getCartDrawer: 'cartDrawer',
      getMobileDrawer: 'mobileDrawer',
      getQuantity: 'cart/quantity',
      settings: 'settings'
    }),

    cartQuantity() {
      return this.getQuantity
    },

    cartDrawer: {
      get() { return this.getCartDrawer },
      set(val) { this.setCartDrawer(val) }
    },

    customerLoginEnabled() {
      return this.settings?.customer_login?.enabled
    },

    mobileDrawer: {
      get() { return this.getMobileDrawer },
      set(val) { this.setMobileDrawer(val) }
    },

    isDefaultLayout() {
      return this.$nuxt.$data.layoutName === 'default'
    },

    themeIsDark() {
      return this.$vuetify.theme.dark
    }
  },
  methods: {
    ...mapMutations({
      setLeftNav: 'setLeftNavDrawer',
      setCartDrawer: 'setCartDrawer',
      setMobileDrawer: 'setMobileDrawer'
    }),

    toggleCart() {
      this.cartDrawer = !this.cartDrawer
    },

    async logout() {
      await this.$store.dispatch('auth/logout')
      if (this.isDefaultLayout) {
        this.$router.push('/login')
      }
    }
  }
}
