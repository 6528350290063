import authState from './auth/state'
import authMutations from './auth/mutations'
import authActions from './auth/actions'
import authGetters from './auth/getters'

export const state = authState

export const mutations = authMutations

export const getters = authGetters

export const actions = authActions
