export * as rules from '~/js/rules'
export * as countries from '~/js/countries'

export const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export const normalizeAddress = address => {
  if (!address) { return }
  const preformatted = address.zip
  if (preformatted) {
    const zip = address.zip.split('-')[0] || address.zip
    return `${address.line_1}, ${zip}`
  } else {
    const full = address
    const streetNumber = full?.street_number
    const route = full?.route
    const zip = full?.postal_code
    const street = address?.address1
      ? `${address?.address1}, ${zip}`
      : `${streetNumber} ${route}, ${zip}`
    return street
  }
}

export const shallowEquals = (object1, object2) => {
  if (object1 === object2) {
    return true
  }
  if (!object1 || !object2) {
    return false
  }
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}
