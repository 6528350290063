export default async function({ app, store }) {
  // get store info
  const hostname = new URL(window.location.href).hostname
  const shopName = process.env.NODE_ENV !== 'production'
    ? hostname.replace('-dot-', '.').split('.')[0]
    : hostname.split('.')[0]

  await store.dispatch('info', process.env.DP_SHOP || shopName).catch(e => {
    console.error('Get shop info error: ', e)
    app.$toast.error('Get shop info error...')
  })
}
