
import { VImg } from 'vuetify/lib'

export default {
  name: 'opsy-image',

  components: { VImg },

  props: {
    src: String,
    size: String,
    splitPattern: String
  },

  methods: {
    _imageSize(url, size) {
      const splitPattern = this.splitPattern || '-url_'
      const base = url.replace('.s3.amazonaws.com/', '.images.s3.controlpad.cloud/').split(splitPattern)
      if (base[1]) {
        const ext = base[1].split('.')[1]
        const imgSize = size || this.$vuetify.breakpoint.name
        return `${base[0]}-url_${imgSize}.${ext}`
      }
      return url
    }
  }
}
