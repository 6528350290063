import { firestoreAction } from 'vuexfire'
/**
 * Actions for Checkout module
 * imported in store/checkout.js
 */
export default {
  /**
   * Get checkout by pid
   * @param {string} checkoutPid
   * @returns {promise}
   */
  getCheckout({ commit }, checkoutPid) {
    return this.$apiCheckout.get(`/checkouts/${checkoutPid}`)
      .then(resp => {
        if (!resp) {
          throw Error
        }
        commit('setSession', resp.data)
        commit('setCheckoutPid', resp.data.pid)
        return resp
      })
  },

  /**
   * Action to create a checkout session
   * @param {object} params
   * @returns {promise}
   */
  createCheckout({ dispatch, commit, rootGetters }, params) {
    const id = rootGetters['cart/id']
    return this.$apiCart.$post(`/carts/${id}/create-checkout`, params)
      .then(resp => {
        if (!resp) {
          throw Error
        }
        commit('setPayment', null)
        commit('setSession', resp)
        commit('setCheckoutPid', resp.pid)
        commit('setCheckoutUpdating', false)
        return resp
      }).catch(e => {
        throw new Error(e)
      })
  },

  /**
   * Action to create an order assist checkout
   * @param {number} id
   * @returns {promise}
   */
  createOrderAssistCheckout({ rootGetters }, id) {
    const oaid = rootGetters.orderAssistID
    return this.$apiCheckout.$post(`/order-assist/checkout/${oaid}/${id}`)
      .then(resp => {
        if (!resp) {
          throw Error
        }
        return resp
      })
  },

  /**
   * Action to process a checkout session
   * @param {object} payload
   * @returns {promise}
   */
  processCheckout({ state, dispatch, commit, rootGetters }, payload) {
    return this.$apiCheckout.$post(`/checkouts/${state.session.pid}/process`, payload)
      .then(async resp => {
        if (!resp) {
          throw Error
        } else {
          const checkoutPayload = {
            ...resp.order,
            ...state.session
          }
          await commit('setReceipt', checkoutPayload, { root: true })
          // Currently checkout service deletes cart from firestore
          // Ignore this delete and create a new cart
          await commit('cart/setId', null, { root: true })
          // dispatch('createCart', { root: true })
          commit('clearSession')
          if (rootGetters.orderAssistID) {
            dispatch('processOrderAssistCheckout', resp.order.pid)
          } else {
            this.$router.push('/checkout/receipt')
          }
        }
      })
  },

  /**
   * Action to process an order assist checkout session
   * @param {object} id
   * @returns {promise}
   */
  processOrderAssistCheckout({ rootGetters }, id) {
    const oaid = rootGetters.orderAssistID
    return this.$apiCheckout.$post(`/order-assist/purchase/${oaid}/${id}`)
      .then(resp => {
        if (!resp) {
          throw Error
        } else {
          this.$router.push('/checkout/receipt')
        }
      })
  },

  /**
   * Action to process a checkout session
   * @param {object} payload
   * @returns {promise}
   */
  addCoupon({ getters, commit }, payload) {
    const pid = getters.session.pid
    return this.$apiCheckout.$patch(`/checkouts/${pid}`, payload)
      .then(resp => {
        if (!resp) {
          throw Error
        } else {
          commit('setSession', resp)
          commit('setCheckoutPid', resp.pid)
        }
      })
  },

  /**********
   * READ
   * **********/

  /**
   * Action to get plans
   * @returns {promise}
   */
  async getAutoshipPlans() {
    const plans = await this.$axios({
      type: 'get',
      url: `${process.env.AUTOSHIP_API_URL}/plans`
    })

    return plans.data
  },

  /**********
   * UPDATE
   * **********/
  /**
   * Action to update a checkout session
   * @param {object} payload
   * @returns {promise}
   */
  updateCheckout({ state, commit }, payload) {
    const pid = state.session.pid
    return this.$apiCheckout.$patch(`/checkouts/${pid}`, payload)
      .then(session => {
        if (!session) {
          throw Error
        }
        commit('setSession', session)
        commit('setCheckoutPid', session.pid)
        return session
      })
  },

  /**********
   * Bind Payman firestore payment result
   * **********/

  bindPaymentResult: firestoreAction(async function({ bindFirestoreRef }, paymanPaymentId) {
    const paymentResultCollection = await this.$fire.firestore.collection('payman/payments/results')
    const fsPayment = await paymentResultCollection.doc(paymanPaymentId)
    console.log('binding payment', { id: paymanPaymentId })
    await bindFirestoreRef('paymentResult', fsPayment).then(r => {
      if (r === null) {
        throw new Error('Error')
      } else {
        console.log('firestore update', r)
        return r
      }
    })
  }),

  unbindPaymentResult: firestoreAction(function({ unbindFirestoreRef }) {
    unbindFirestoreRef('paymentResult')
  })
}
