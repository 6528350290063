/**
 * Getters for Events module
 * imported in store/events.js
 */

export default {

  /**
  * Get all events
  *
  * @param {object} all
  * @returns {boolean}
  */
  all: ({ all }) => all
}
