
import UserMenu from '@/components/ui/menus/user'

export default {
  name: 'terms-layout',

  middleware: ['guards', 'shop', 'theme'],

  components: { UserMenu },

  computed: {
    shopName() {
      return this.$store.getters.settings.company.name
    }
  }
}
