/**
 * State for Cart module
 * imported in store/cart.js
 * @return {function}
 */
export default function() {
  return {
    id: null,
    instance: null,
    expired: false,
    expiresAt: null,
    cartLoading: true,
    cartUpdating: false
  }
}
