
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CartCard from '@/components/cart/cart-card'

export default {
  name: 'shopping-cart-drawer',

  components: {
    CartCard
  },

  computed: {
    ...mapGetters({
      cartDrawer: 'cartDrawer',
      cartlines: 'cart/lines',
      cartQuantity: 'cart/quantity',
      cartUpdating: 'cart/cartUpdating',
      isLoading: 'cart/cartLoading'
    }),

    themeIsDark() {
      return this.$vuetify.theme.dark
    },

    drawer: {
      get() { return this.cartDrawer },
      set(v) { this.setCartDrawer(v) }
    },

    cartTotal() {
      const arr = []
      if (!this.cartlines) { return }

      this.cartlines.reduce((acc, cur) => {
        const qty = cur.quantity
        const price = cur.price
        return arr.push(qty * price)
      }, 0)

      return arr.reduce((a, b) => a + b, 0)
    }
  },

  async created() {
  },

  methods: {
    ...mapMutations({
      setCartDrawer: 'setCartDrawer'
    }),
    ...mapActions({
      clearCartAction: 'cart/clearCart'
    }),

    checkout() {
      this.drawer = false
      this.$router.push('/checkout')
    },

    clearCart() {
      this.clearCartAction().then(() => this.drawer = false)
    }
  }
}
