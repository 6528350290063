/* eslint-disable no-useless-escape */
import Vue from 'vue'
import * as utils from '~/js/utils'

export default ({ $vuetify }, inject) => {
  // check if viewport is mobile size
  Vue.prototype.$isMobile = () => {
    return $vuetify.breakpoint.xsOnly
  }

  // check if is the current environment is dev
  Vue.prototype.$isDev = process.env.NODE_ENV === 'development'

  // check if is the current environment is dev
  Vue.prototype.$isProd = process.env.NODE_ENV !== 'development'

  Vue.prototype.$utils = utils
  inject('utils', utils)
}
