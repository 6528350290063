/**
 * Mutations for Events module
 * imported in store/events.js
 */
export default {
  /**
   * Save the events to state
   * @param {object} state
   * @param {array} events
   */
  setEvents(state, events) {
    state.all = events
  }
}
