/**
 * Global Config
 */

export const strings = {
  // Port number for dev
  PORT: process.env.PORT || '3000',
  HOST: process.env.NODE_HOST || 'localhost',

  // Base url env var
  get BASE_URL() {
    return process.env.BASE_URL || `http://localhost:${this.PORT}`
  },

  // Local storage name
  VUEX_LOCAL_STORAGE_KEY: 'o-shop-store',

  // Deploy Previews setup
  DP_SHOP: process.env.DP_SHOP,
  DP_TENANT: process.env.DP_TENANT,

  // Set Node env var
  NODE_ENV: process.env.NODE_ENV || 'development',

  // Set google env vars
  GOOGLE_ENV: process.env.GOOGLE_ENV || 'staging',
  GOOGLE_TENANT_ID: process.env.GOOGLE_TENANT_ID || 'staging-nbj4b',

  // Set tenant name
  TENANT_ID: process.env.TENANT_ID || 'bombparty',
  CHECKOUT_SITE_URL: process.env.CHECKOUT_SITE_URL || null,

  BUGSNAG_API_KEY: process.env.BUGSNAG_API_KEY || null,

  // API Endpoint Environment Variables
  CARTS_API_URL: process.env.CARTS_API_URL || 'https://carts-dot-staging-controlpad-cloud.uc.r.appspot.com/api/v0',
  USERS_API_URL: process.env.USERS_API_URL || 'https://users-api.controlpad.dev/api/v0',
  INVENTORY_API_URL: process.env.INVENTORY_API_URL || 'https://inventories-dot-staging-controlpad-cloud.appspot.com/api/v0',
  EVENTS_API_URL: process.env.EVENTS_API_URL || 'https://events-api.controlpad.dev/api/v0',
  CORE_API_URL: process.env.CORE_API_URL || 'https://controlpad.com',
  CLOUD_AUTH_API_URL: process.env.CLOUD_AUTH_API_URL || 'https://auth-dot-staging-controlpad-cloud.appspot.com/api',
  CHECKOUT_API_URL: process.env.CHECKOUT_API_URL || 'https://checkout-dot-staging-controlpad-cloud.uc.r.appspot.com/api/v0',
  AUTOSHIP_API_URL: process.env.AUTOSHIP_API_URL || 'https://autoship-api.controlpad.dev/api/v0',
  AUTH_API_URL: process.env.AUTH_API_URL || 'https://auth.controlpadapi.dev/api/v0'
}

export const head = {
  meta: [
    { charset: 'utf-8' },
    { hid: 'description', name: 'description', content: 'Premiere Influencer Marketing Platform' },
    // PWA settings
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'apple-mobile-web-app-capable', content: 'yes' }
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/img/favicon.png' },
    // PWA Icons
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/img/pwa/apple-icon-180.jpg' },
    { rel: 'apple-touch-icon', sizes: '167x167', href: '/img/pwa/apple-icon-167.jpg' },
    { rel: 'apple-touch-icon', sizes: '152x152', href: '/img/pwa/apple-icon-152.jpg' },
    { rel: 'apple-touch-icon', sizes: '120x120', href: '/img/pwa/apple-icon-120.jpg' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2048-2732.jpg', media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2732-2048.jpg', media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1668-2388.jpg', media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2388-1668.jpg', media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1668-2224.jpg', media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2224-1668.jpg', media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1536-2048.jpg', media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2048-1536.jpg', media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1242-2688.jpg', media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2688-1242.jpg', media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1125-2436.jpg', media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2436-1125.jpg', media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-828-1792.jpg', media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1792-828.jpg', media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1242-2208.jpg', media: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-2208-1242.jpg', media: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-750-1334.jpg', media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1334-750.jpg', media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-640-1136.jpg', media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
    { rel: 'apple-touch-startup-image', href: '/img/pwa/apple-splash-dark-1136-640.jpg', media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' }
  ]
}

/**
 * Transitions
 */
export const transitions = {
  page: {
    // name: 'slide-y-transition',
    // mode: 'out-in'
  },
  layout: {
    name: 'slide-y-transition',
    mode: 'out-in'
  },
  loginRegister: {
    name: 'fade-transition',
    mode: 'out-in'
  }
}
