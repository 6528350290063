const middleware = {}

middleware['checkout-cart'] = require('../middleware/checkout-cart.js')
middleware['checkout-cart'] = middleware['checkout-cart'].default || middleware['checkout-cart']

middleware['guards'] = require('../middleware/guards.js')
middleware['guards'] = middleware['guards'].default || middleware['guards']

middleware['noauth'] = require('../middleware/noauth.js')
middleware['noauth'] = middleware['noauth'].default || middleware['noauth']

middleware['shop'] = require('../middleware/shop.js')
middleware['shop'] = middleware['shop'].default || middleware['shop']

middleware['theme'] = require('../middleware/theme.js')
middleware['theme'] = middleware['theme'].default || middleware['theme']

export default middleware
