/**
 * Actions for Cart module
 * imported in store/cart.js
 */
import { firestoreAction } from 'vuexfire'
export default {

  /**********
   * CREATE
   * **********/

  /**
   * Action to create a checkout session
   * @param {object} payload
   * @returns {promise}
   */
  createCart({ commit, dispatch }, payload) {
    return this.$apiCart.$post('/carts', payload)
      .then(async resp => {
        if (!resp) {
          throw Error
        }
        await commit('setId', resp.pid)
        await dispatch('bindCart', resp.pid, { root: true })
        return resp
      }).catch(() => {})
  },

  /**
   * Action to create a checkout session
   * @param {object} payload
   * @returns {promise}
   */
  addCartItem({ rootGetters }, payload) {
    const cartId = rootGetters['cart/id']
    try {
      return this.$apiCart.$post(`/carts/${cartId}/lines`, [payload])
        .then(resp => {
          return resp
        })
    } catch (error) {
      console.error('add cart item error: ', error)
    }
  },

  /**********
   * READ
   * **********/

  /*** Firestore Action to bind cart items */
  bindCart: firestoreAction(async function({ commit, bindFirestoreRef }, cartId) {
    const cartsCollection = await this.$fire.firestore.collection('carts')
    const fsCart = await cartsCollection.doc(cartId.toString())
    console.log('binding cart', { id: cartId })
    await bindFirestoreRef('instance', fsCart).then(r => {
      if (r === null) {
        console.log('bind error, ref null')
        throw new Error('Error')
      } else {
        console.log('bound to', r)
        return r
      }
    })
    commit('toggleCartLoading', false)
  }),

  /**
   * Action to get a checkout session
   * @param {number} id - id of the cart to get
   * @returns {promise}
   */
  async getCart({ commit, rootGetters }, id) {
    const cartId = id || rootGetters['cart/id']
    const cart = await this.$apiCart.$get(`/carts/${cartId}?expands[]=lines`).catch(() => commit('clearCheckout'))

    await commit('setInstance', cart)
  },

  /**********
   * UPDATE
   * **********/
  /**
   * Action to update an item quantity in a cart
   *
   * @param {object} item
   * @returns {promise}
   */
  async updateQuantity({ dispatch }, item) {
    await this.$apiCart.patch(`/cartlines/${item.pid}`, {
      quantity: item.quantity
    }).catch(() => {})
  },

  /**
   * Action to update multiple cart lines of a cart
   *
   * @param {object} payload
   * @returns {promise}
   */
  async updateCartLines({ dispatch }, payload) {
    await this.$apiCart.patch(`/carts/${payload.pid}/lines`, payload.data).catch(() => {})
  },

  /**********
   * DELETE
   * **********/
  /**
   * Action to delete an item from the cart
   *
   * @param {object} item
   */
  async deleteItem({ dispatch }, item) {
    await this.$apiCart.delete(`/cartlines/${item.pid}`)
  },

  /**********
   * Empty
   * **********/
  /**
   * Action to empty and reset the cart
   *
   * @param {number} id
   * @returns {promise}
   */
  emptyCart({ dispatch, commit }, id) {
    commit('toggleCartLoading', true)
    return this.$apiCart.get(`/carts/${id}/empty`)
      .then(resp => {
        // Local expiration only sets on create/refresh of cart
        console.log('cartEmptied', resp)
        commit('toggleCartLoading', false)
        commit('setExpired', false)
        return resp
      }).catch(error => {
        console.error('clear cart error', error)
        dispatch('createCart', null, { root: true })
      })
  }
}
