import moduleMutations from './inventory/mutations'
import moduleActions from './inventory/actions'
import moduleGetters from './inventory/getters'

export const state = () => ({
  products: []
})

export const mutations = moduleMutations

export const getters = moduleGetters

export const actions = moduleActions
