
import { mapGetters } from 'vuex'
import Breadcrumbs from '@/components/navigation/breadcrumbs'
import OpsyFooter from '@/components/footer'
import CartExpiredModal from '@/components/cart/cart-expired-modal'

export default {
  name: 'default-layout',

  layout: 'checkout',

  components: { Breadcrumbs, OpsyFooter, CartExpiredModal },

  computed: {
    ...mapGetters({
      authed: 'auth/authed',
      settings: 'settings'
    }),
    pageTitle() {
      const titleArray = this.$route.path.split('/')
      titleArray.shift()

      if (titleArray[1] === '') {
        return 'cart'
      }

      return titleArray[1]
    },
    shopName() {
      return this.settings?.company?.name
    },
    shopLogo() {
      return this.settings?.images?.company_logo
    }
  },

  mounted() {
    const defaultPrimary = '#696969'
    const themes = this.settings?.themes?.themes
    const lightBrandColor = themes?.light.primary || defaultPrimary
    const darkBrandColor = themes?.dark.primary || defaultPrimary
    this.$nextTick(() => {
      this.$vuetify.theme.dark = this.settings?.themes?.dark
      this.$vuetify.theme.themes.light.primary = lightBrandColor
      this.$vuetify.theme.themes.dark.primary = darkBrandColor
    })
  }
}
