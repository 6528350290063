import Vue from 'vue'
import { SelfBuildingSquareSpinner } from 'epic-spinners/dist/lib/epic-spinners.min.js'
import OpsyImage from '~/components/image/opsy-image'
import OpsyLogo from '~/components/image/logo'
import OpsyCircleLoader from '~/components/loaders/circle-loader'

Vue.component('app-loader', SelfBuildingSquareSpinner)
Vue.component('opsy-image', OpsyImage)
Vue.component('opsy-logo', OpsyLogo)
Vue.component('opsy-circle-loader', OpsyCircleLoader)
