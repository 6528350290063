import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'
import {
  clickout,
  autofocus,
  copy,
  intersect
} from '../directives'

Vue.directive('clickout', clickout)
Vue.directive('autofocus', autofocus)
Vue.directive('copy', copy)
Vue.directive('intersect', intersect)
Vue.directive('mask', (input, m) => {
  if (m.value) {
    return VueMaskDirective.bind(input, m)
  }
})
