
import { mapGetters } from 'vuex'
import { VBtnToggle, VBtn } from 'vuetify/lib'

export default {
  name: 'theme-changer',

  components: { VBtnToggle, VBtn },

  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      settings: 'settings'
    }),

    theme: {
      get() {
        const theme = this.$store.getters.userThemeChoice || this.$store.getters.darkTheme
        return theme
      },
      set(selection) {
      }
    },

    selections() {
      return [
        { value: 2, text: 'Light' },
        { value: 3, text: 'Dark' }
      ]
    }
  },

  beforeDestroy() {
    this.matchMedia().removeListener(this.checkSystemPreference)
  },

  mounted() {
    /****  THIS IS FOR AUTOFORMATTING THE THEME BASED ON USERS SYSTEM SETTINGS */
    // this.darkTheme = this.matchMedia().matches
    this.matchMedia().addListener(this.checkSystemPreference)
  },

  methods: {
    changeTheme(selection) {
      this.theme = selection.value

      switch (selection.value) {
        case 2:
          this.$vuetify.theme.dark = false
          break

        case 3:
          this.$vuetify.theme.dark = true
          break

        default:
          break
      }

      this.$store.commit('setUserThemeChoice', selection.value)
    },

    checkSystemPreference(e) {
      this.darkTheme = e.matches
    },

    matchMedia() {
      return window.matchMedia('(prefers-color-scheme: dark)')
    }
  }
}
