import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.NODE_ENV,
  otherOptions: {}
}

let bugsnagClient

export default ({ app }, inject) => {
  // Ignore bugsnag when key is not set
  if (!options.apiKey) { return }
  // Add app version to logs
  options.appVersion = app.$config.version
  // Create client
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(options)
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}
