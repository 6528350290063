/**
 * Vuetify config
 * @returns {object}
 */
export default ({ app }) => {
  return {
    treeShake: true,
    icons: {
      iconfont: 'mdiSvg'
    },
    theme: {
      options: {
        customProperties: true
      },
      themes: {
        dark: {
          primary: '#696969'
        },
        light: {
          primary: '#696969'
        }
      }
    },
    lang: {
      // This code pulled from opsy-js, but does not work, never seemed to be wired up
      // vuetify badge tries to pull a key '$vuetify.badge' which isn't set for some reason, and i18n wouldn't be able to pull that variable anyway
      // nuxt config was referencing an incorrect file path for this file, and was never actually loading it
      // t: (key, ...params) => {
      //   app.i18n.t(key, params)
      // }
    }
  }
}
